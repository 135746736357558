@import "../template/mixin_templates";

@keyframes opacityMenu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/***** GENERAL *****/
body {
  font-family: Helvetica, Arial, sans-serif;
  margin: 0;
}

@include titreBaseSize();

b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.bx-wrapper .bx-viewport {
  left: 0;
}

.content h1, .content h2, .content h3,
.content h4, .content h5, .content h6,
#tinymce h1, #tinymce h2, #tinymce h3,
#tinymce h4, #tinymce h5, #tinymce h6,
.content .h1, .content .h2, .content .h3,
.content .h4, .content .h5, .content .h6,
#tinymce .h1, #tinymce .h2, #tinymce .h3,
#tinymce .h4, #tinymce .h5, #tinymce .h6 {
  margin-bottom: .5rem;
  margin-top: .2rem;
  line-height: 1.4;
  font-weight: normal;
}

h2, .h2, h1, .h1 {
  font-size: 1.6rem;
  display: table;
  padding: 2px 24px;
  margin: 15px auto;
  position: relative;
  border-radius: 20px;
}

h2 span, .h2 span, h1 span, .h1 span {
  vertical-align: middle;
}

.content h1, .content .h1 {
  font-size: 2rem;
}

.content h3, .content .h3 {
  font-size: 1.6rem;
}

.content h4, .content .h4 {
  font-size: 1.5rem;
}

.content h5, .content .h5 {
  font-size: 1.4rem;
}

.content h6, .content .h6 {
  font-size: 1.3rem;
}

a {
  text-decoration: underline;
}

p {
  margin-bottom: 1.2rem;
  line-height: 1.4rem;
}

ul, ol {
  margin-bottom: .5rem;
}

li > ul {
  margin-left: 1.9rem;
  list-style-type: inherit;
}

li {
  line-height: 1.2;
}

blockquote {
  padding: 10px 10px;
  border-left: 1px solid #ccc;
  margin: 20px 0;
}

blockquote cite {
  display: block;
  font-size: .85rem;
  font-style: italic;
}

/* Fil d'Ariane */
.content nav.arianne, #fiche-produit nav.secondaire {
  text-align: left;
  font-size: .8rem;
  margin: 0 0 20px 0;
  ul {
    list-style-type: none;
    font-size: 0;
    display: block;
  }
  li {
    border: none;
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
    font-size: .8rem;
    &:hover {
      animation-name: hvr-wobble-horizontal;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      z-index: 50;
    }
    a {
      border: 1px solid;
      border-radius: 20px;
      padding: 6px 15px;
      text-decoration: none;
      display: inline-block;
    }
  }
}

/*#mainconteneur header{
    z-index: 1051;
}*/

/***** HEADER *****/
header {
  padding-top: 8px;
  position: relative;
  top: 0 !important;
  left: 0;
  z-index: 10;
  width: 100%;
  transition: top .2s linear;
  z-index: 1050;
  &.header-fixed.header-fixed-anim {
    animation-name: opacityMenu;
    animation-duration: .5s;
  }
}

.wrapper-content {
  //z-index: 1;
  position: relative;
}

#minisite-sidebar {
  z-index: 1051 !important;
}

body .header_p {
  background-repeat: no-repeat;
  position: relative;
  z-index: 1006;
}

.cmonsite-panier-3 #main-conteneur .header_p {
  //position: fixed;
}

.wrapper.wrapper-fullpage {
  width: 100%;
}

#headercontent {
  max-width: 1060px;
  height: 100px;
  margin: auto;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 23%;
  max-height: 100px;
}

.logo img {
  /*    width: 100%;*/
  max-height: 100px;
  max-width: 100%;
}

.slogan {
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  top: 28%;
  left: 24%;
  margin: 0;
}

/** Animation Horizontale Menu **/
@keyframes hvr-wobble-horizontal {
  16.65% {
    transform: translateX(8px);
  }

  33.3% {
    transform: translateX(-6px);
  }

  49.95% {
    transform: translateX(4px);
  }

  66.6% {
    transform: translateX(-2px);
  }

  83.25% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0);
  }
}

header .menu.navigation_p::after {
  content: '';
  display: block;
  height: 1px;
  clear: both;
}

nav#nav-principal {
  text-align: center;
  margin: 10px auto;
  padding: 0 8px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  ul {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    ul ul {
      display: none;
      position: absolute;
      top: -2px;
      left: 100%;
      min-width: 100%;
      margin: 0;
    }
  }
  li {
    margin-right: 4px;
    margin-bottom: 3px;
    position: relative;
    &:hover > ul {
      display: block;
    }
    li {
      margin: 0;
      &:first-child {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      &:last-child {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      a {
        padding: 3px 20px;
        line-height: 1.7;
        word-wrap: initial;
        white-space: nowrap;
        word-break: initial;
      }
    }
  }
  li > a {
    padding: 11px 24px;
    display: block;
    text-decoration: none;
    line-height: 1.2;
  }
  li ul {
    position: absolute;
    left: 0;
    display: none;
    z-index: 100;
    top: initial;
    margin-top: 2px;
    border-radius: 20px;
    white-space: nowrap;
    text-align: center;
    min-width: 150px;
    border: 2px solid;
    border-color: inherit;
    a {
      border-bottom: 1px solid;
      border-bottom-color: currentColor;
    }
    li {
      border-color: inherit;
    }
    li:last-child > a {
      border: none !important;
    }
  }
  > ul > li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    transition: opacity 1s ease-out;
    backface-visibility: hidden;
    border-radius: 20px;
    border: 2px solid;
    &:hover {
      animation-name: hvr-wobble-horizontal;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      z-index: 50;
    }
  }
}

.navbar-fixed-top {
  box-shadow: 0 4px 2px rgba(11, 25, 28, 0.3);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=174, Strength=10);
}

.navbar-fixed-top #headercontent {
  height: auto;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
  #logo, .container-slogan {
    opacity: 0;
    height: 0 !important;
    overflow: hidden;
  }
}

/***** FIN HEADER *****/
.wrapper-content {
  min-height: 550px;
  margin: 10px auto 30px auto;
  overflow: hidden;
}

/***** CONTENU *****/

#main-conteneur {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
  }
}

.wrapper {
  width: 1240px;
  margin: auto;
  padding: 30px 30px 50px 30px;
  box-sizing: border-box;
  max-width: 95%;
  &.accueil_diaporama_template_5 {
    padding-bottom: 0;
  }
  iframe {
    max-width: 100%;
  }
}

.wrapper-content > .wrapper {
  &:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  &:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.accueil_diaporama_template_1 {
    padding: 30px 30px 1px;
  }
  &.accueil_diaporama_template_2 {
    border-radius: 0;
    .bx-wrapper {
      margin-bottom: 0;
    }
  }
}

.page img {
  max-width: 100%;
}

.content .nav-static-breadcrumb, #fiche-produit nav.secondaire {
  text-align: left;
  font-size: .8rem;
  margin: 0 0 20px 0;
}

.content .nav-static-breadcrumb li, #fiche-produit nav.secondaire li {
  border: none;
}

.content .nav-static-breadcrumb a, #fiche-produit nav.secondaire a {
  border: 1px solid;
  border-radius: 20px;
  padding: 6px 15px;
}

a.bouton, .button, a.button, input[type=submit], a.addmsglo, .addbasket, .diaporama a {
  padding: 7px 22px;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid;
}

a.bouton:hover, .button:hover, a.button:hover, input[type=submit]:hover, a.addmsglo:hover, .addbasket:hover, .diaporama a:hover {
  padding: 6px 20px;
}

.content form.formulaires_perso input[type=submit], .form-newsletter input[type=submit] {
  padding: 7px 22px !important;
  border: 1px solid !important;
}

.formulaires_perso {
  border: 1px solid #ccc;
}

.form-newsletter {
  border-radius: 0;
}

.formulaires_perso .roundedgraymsg {
  border: none;
}

.formulaires_perso div.center, .form-newsletter .center {
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: normal;
  margin-bottom: 5px;
}

.formulaires_perso label.qs_form {
  border-bottom: none;
  padding-bottom: 0;
}

.formulaires_perso input, .formulaires_perso textarea, .form-newsletter input {
  box-sizing: border-box;
}

.formulaires_perso input, .form-newsletter input {
  height: 31px;
}

.formulaires_perso input[type=text] {
  width: 100% !important;
}

.formulaires_perso input[type=checkbox] {
  margin-bottom: 0;
  height: auto;
}

.formulaires_perso input[type=file], input[type=file] {
  padding: 0 !important;
  height: auto;
}

.formulaires_perso textarea {
  height: 150px;
  width: 100% !important;
}

/* Accueil */
.content .bx-wrapper .bx-viewport {
  left: 0;
}

.bx-wrapper .bx-viewport {
  left: 0 !important;
}

.content .bx-wrapper .bx-viewport img:not(.imgZoomPad) {
  max-width: 100%;
  width: auto;
  margin: auto;
}

.content .bx-wrapper .bx-controls-direction a, .content .banner_diaporama .bx-wrapper .bx-controls-direction a {
  z-index: 80;
}

#bx-pager {
  display: none;
  text-align: center;
  margin-bottom: 50px;
}

#bx-pager > a {
  display: inline-block;
}

#bx-pager img {
  width: 150px;
}

.produits-accueil .bx-wrapper {
  margin: 0 auto !important;
}

.accueil_boutique_template_2 .list-produits .produits .remise {
  left: 10px;
}

/* Boutique */
.boutique_header img {
  max-width: 100% !important;
  height: auto !important;
}

.template_boutique_all .produits {
  padding: 10px;
}

.produits {
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  font-size: 1rem;
  *, *::before, *::after {
    box-sizing: inherit;
  }
  .container-etiquette {
    //height: 32px;
    .produit_etiquette {
      height: auto;
      width: 100%;
      position: absolute;
      h3, .h3 {
        text-align: center;
        font-size: 1.2rem;
        padding: 3px 5px;
        z-index: 1;
        text-transform: none;
        margin: 0;
        white-space: nowrap;
      }
      &::after {
        border: none;
      }
    }
  }
  &.is_etiquette .remise, .remise {
    /*    display: inline;*/
    position: absolute;
    border-radius: 0 0 50px 0;
    left: 10px;
    padding: 8px 10px 19px 5px;
    font-weight: bold;
    font-size: 12px;
    z-index: 21;
    display: block;
    top: 10px;
    box-sizing: border-box;
    width: 51px;
    height: 51px;
    text-align: center;
  }
  &.is_etiquette .remise {
    top: 32px;
  }
  .productImageWrap {
    overflow: hidden;
    position: relative;
    img {
      height: auto;
      display: block;
      width: 100%;
      border-radius: 20px;
    }
  }
  .mask {
    height: 100%;
    margin: 0;
    text-align: center;
    width: 100%;
    z-index: 20;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    transition: all .2s linear;

    /* Hack pour la personnalisation, pour eviter de récupérer le style de bouton de base */
    background: transparent !important;
    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }
  /*.lien-mask {
      height: 100%;
      left: 0;
      filter: alpha(opacity=60);
      opacity: 0.6;
      position: absolute;
      top: 0;
      width: 100%;
  }*/
  .zoom {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: #000;
    color: #fff;
    font-size: 15px;
    padding: 8px 18px;
    text-align: center;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    box-sizing: border-box;
    border-radius: 20px;
    .fa {
      font-size: 1.2rem;
      margin-right: 5px;
    }
    &:hover {
      padding: 7px 17px;
    }
  }
  .nomprod {
    margin: 6px 0 10px;
    padding: 5px 10px;
    border-radius: 20px;
    text-align: center;
    box-sizing: border-box;
    a {
      text-decoration: none;
      text-transform: uppercase;
      line-height: normal;
    }
  }
  .desc {
    line-height: 1.2rem;
    font-size: .9rem;
  }
  .prix {
    text-align: center;
    margin-top: 10px;
    font-size: .95rem;
    line-height: 1.2rem;
    .oldprix {
      text-decoration: line-through;
    }
  }
  .attr {
    position: relative;
  }
  select {
    z-index: 100;
    margin-top: 10px;
    width: auto;
    min-width: 75px;
    padding: 2px 5px;
  }
  .addbasket, .hors-stock {
    font-size: .8rem;
    text-align: center;
    padding: 6px 20px;
    box-sizing: border-box;
    .fa {
      margin-right: 7px;
    }
  }
  .note-produit {
    text-align: center;
  }
}

.template_boutique {
  &.template_boutique_1 .produits {
    .container-etiquette {
      margin-top: -5px;
      position: absolute;
      top: 10px;
      width: 100%;
    }
    .attr:empty {
      display: none;
    }
    .block-btn-addbasket {
      align-items: flex-end;
      justify-content: flex-start;
      margin: 0 0 15px;
      clear: none;
      .addbasket, .hors-stock {
        margin-top: 5px;
        padding: 10px;
        &:not(.next-select) {
          width: auto;
          max-width: 80%;
          margin: 0 auto;
        }
        &.next-select {
          width: 95%;
          display: block;
        }
      }
    }
  }
  &.template_boutique_2 .produits {
    .container-etiquette {
      margin-top: -5px;
      position: absolute;
      top: 10px;
      width: 100%;
    }
  }
  &.template_boutique_3 .produits.is_etiquette .nomprod {
    margin-top: 0;
  }
  &.template_boutique_4 .produits {
    .container-etiquette {
      margin-top: -5px;
      position: absolute;
      top: 10px;
      width: 100%;
    }
    .container-etiquette .produit_etiquette {
      position: relative;
    }
    .prix {
      margin-top: 10px;
    }
    .remise {
      top: 37px;
    }
  }
  &.template_boutique_5 .produits {
    &.is_etiquette .remise {
      top: 43px;
    }
  }
}

.lists-produits {
  position: absolute;
  right: 10px;
  bottom: -30px;
}

/* Fiche produit */

#fiche-produit {
  .produit_etiquette {
    width: 100%;
    .produit_etiquette {
      height: auto;
    }
    h3, .h3 {
      padding: 6px 0;
      margin: 0;
    }
  }
  .wrap-images {
    box-sizing: border-box;
    .remise-produit {
      //display: none;
    }
    .previews .list-previews, .previews ul {
      height: 100px;
      li {
        border: 1px solid #ccc;
        box-sizing: border-box;
        height: 100px;
        width: 100px;
      }
    }
    .previews img {
      width: 100%;
    }
    .previews .previous, .previews .next {
      font-size: 2rem;
      line-height: 2.2;
    }
    button {
      width: 100%;
      padding: 5px;
      cursor: pointer;
      background-color: #e8e8e8;
      border: 1px solid #ccc;
      &:hover {
        background-color: #fff;
        border: 1px solid #8e8e8e;
      }
    }
    div.previews + div a {
      width: 100%;
    }
    .grande-image {
      &, > a {
        height: auto;
      }
    }
  }
  .wrap-description {
    h1, .h1 {
      margin-top: 0;
      font-size: 32px;
      font-weight: bold;
      padding: 0;
    }
    h1 + span, .h1 + span {
      font-size: 16px;
    }
    .brand {
      //Passer au-dessus de la personnalisation design
      background: transparent !important;
      color: inherit !important;
    }
    label {
      font-size: 1rem;
      font-weight: normal;
      margin-right: 5px;
      & + select, & + input[type=text] {
        font-size: .9rem;
        padding: 3px 5px;
      }
    }
    p.note-produit {
      font-size: 18px;
      margin: 5px 0 35px;
      i {
        margin-right: 1px;
      }
      a {
        margin-left: 5px;
      }
    }
    .triangle-ficheprod,
    .triangle-ficheprod-second {
      border: none;
      position: absolute;
      top: 30px;
      left: 15px;
      z-index: 100;
      .remise-produit {
        border-radius: 0;
        position: relative;
        white-space: nowrap;
        font-size: .8rem;
        height: 15px;
        padding: 1px 5px 5px;
        width: auto;
        display: inline-block;
      }
    }
    .prix {
      float: left;
      text-align: left;
      width: 50%;
      border: none;
    }
    .declinaison-attribute-group, .product_options-group {
      margin-bottom: 5px;
      float: none;
      width: 100%;
    }
    .declinaison select {
      min-width: 70px;
      padding: 8px;
    }
    p.description {
      border-top: 1px solid #ccc;
      margin-top: 20px;
      padding-top: 15px;
    }
    .details-ficheprod {
      font-size: .8rem;
      overflow: hidden;
      a {
        display: inline-block;
        line-height: 1;
      }
    }
    .ajouter-panier {
      &.block-price {
        background-color: #eee;
        box-shadow: 5px 5px 5px 0 #9b9b9b;
        box-sizing: border-box;
        filter: progid:DXImageTransform.Microsoft.Shadow(color=#9b9b9b, Direction=134, Strength=5);
        float: none;
        margin: 10px 0 15px;
        overflow: hidden;
        padding: 10px;
        width: 100%;
        display: inline-block;
      }

      &.block-addbasket {
        display: inline-block;
        width: auto;
      }

    }
    .bloc-quantite {
      justify-content: left;
      margin-bottom: 14px;
      .quantite {
        border-radius: 0;
        padding: 0 12px;
      }
      .bt-quantity {
        height: auto;
        border-color: #a9a9a9;

        &.less-quantity {
          border-radius: 20px 0 0 20px;
          padding: 5px 16px;
        }

        &.add-quantity {
          border-radius: 0 20px 20px 0;
          padding: 5px 16px;
        }
      }
    }
    .addbasket {
      display: inline-block;
      font-size: 1.5em;
      margin: 15px;
      padding: 7px 28px;
    }
    .sociaux {
      border-bottom: none;
    }
    > .row {
      margin-bottom: 10px;
    }
  }
  .wrap-details {
    border-top: 1px solid #ccc;
    margin-top: 15px;

    h4 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
      text-align: center;
    }
    &#savoirplus, &#produit-comment {
      box-sizing: border-box;
      padding: 20px 10px;
    }
  }
  .notification_product {
    margin-left: 5px;
    vertical-align: middle;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    &.error {
      color: #EE0A0A;
      float: left;
    }
    &.hors-stock {
      height: auto;
      margin-top: 0;
      float: none;
    }
    &.aucun-attribut {

    }
  }
  input[type="text"] {
    border: 1px solid #a9a9a9;
    border-radius: 20px;
    padding: 8px;
  }
  .pro-pannier label {
    font-size: .9rem;
  }
  .declinaison {
    float: left;
    width: 100%;
  }
  a {
    margin-top: 0;
  }
  .produits .zoom {
    float: none;
  }
  .ajouter-panier i {
    margin-right: 7px;
  }
  #produit-comment {
    > div > .pull-left {
      font-size: 20px;
      .pull-left:first-child {
        font-weight: bold;
        margin-right: 15px;
      }
      .pull-left:last-child {
        p {
          margin-bottom: 2px;
        }
        span {
          font-size: 26px;
          font-weight: bold;
        }
      }
    }
    > div > .pull-right {
      i {
        margin-right: 5px;
      }
    }
    .message-wrap {
      .pull-left:first-child {
        width: 170px;
        div span {
          font-size: 1.2em;
          margin-left: 5px;
        }
        > span:nth-child(5) {
          display: inline-block;
          margin: 10px 0;
        }
      }
    }
    #addcomment h4 {
      margin-bottom: 10px;
    }
  }
  .container-comment #entete {
    margin-bottom: 15px;
    text-align: center;
  }
  form .form-control {
    border-radius: 20px;
  }
  form .input-form-moyen {
    width: 43.5%;
  }
  form .input-form-petit {
    width: 12.66667%;
  }
  form textarea.form-control {
    min-height: 150px;
  }
  #captcha input {
    padding: 13px;
    font-size: 1.2rem;
    height: auto;
    margin-top: -7px;
  }
  .message-wrap, .container-comment > div {
    border-radius: 20px;
  }
  .zoomPad > img {
    width: 100%;
  }
  p.zoom-img-position {
    *zoom: 1;
    &::after, &::before {
      display: table;
      content: "";
      line-height: 0
    }
    &::after {
      clear: both
    }
  }
  .top_fiche {
    overflow: hidden;
  }
  .fil-dariane li {
    margin-right: 8px;
    a {
      font-weight: normal;
      font-size: .8rem;
    }
  }
}

.template_fiche_produit {
  &.template_fiche_produit_1 #fiche-produit {
    .declinaison {
      float: none;
      width: 100%;
      .declinaison-attribute-group, .product_options-group {
        label:first-child {
          margin: 5px 5px 5px 0;
        }
        .radio_unique, .radio_color {
          margin: 5px;
        }
        select {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .bloc-quantite {
      input {
        text-align: center;
      }
    }
  }
  &.template_fiche_produit_2 #fiche-produit {
    .container-etiquette {
      max-width: calc(100% - 1rem);
    }
    .wrap-images {
      padding: 0;
    }
    .triangle-ficheprod {
      top: 0;
      right: 0;
      .remise-produit {
        right: 0;
        left: auto;
      }
    }
    .declinaison {
      float: none;
      width: 100%;
      .declinaison-attribute-group, .product_options-group {
        label:first-child {
          margin: 5px 5px 5px 0;
        }
        .radio_unique, .radio_color {
          margin: 5px;
        }
        select {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  &.template_fiche_produit_3 #fiche-produit {
    .container-etiquette {
      max-width: calc(100% - 1rem);
    }
    .wrap-description .col-sm-3.side-tabs a {
      border: 1px solid;
    }
  }
}

#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > .button {
    flex: 0 1 auto;
    font-size: 1.5em;
    margin: 15px 0;
  }

  > .button + .button {
    margin-left: 15px;
  }
}

.modal {
  z-index: 2041;
}

.modal-backdrop {
  z-index: 2040;
}

.search-bar {
  input {
    height: 29px;
    box-sizing: border-box;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .search-bar-icon button {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.paiements label > span:last-child {
  margin-top: 10px;
}

/* Fin Boutique */

/* Livre d'or */

a.addmsglo {
  display: block;
  margin: 20px auto 30px;
  width: 335px;
  text-align: center;
}

.list-messages::before, .list-messages::after {
  content: "";
  display: table;
  line-height: 0;
}

.list-messages::after {
  clear: both;
}

.message_lo {
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0 0 1px #bbb;
  margin-bottom: 10px;
  width: 49%;
  float: left;
  margin-right: 1%;
  position: relative;
  //background-color: #FFF;
  overflow: hidden;

  h4 {
    font-size: .8rem;
    border-bottom: 1px solid #ccc;
    padding: 3px 12px;
    margin: 0;
    width: 86%;
  }
  p {
    margin: 0;
    padding: 0 10px;
  }
  .note {
    position: absolute;
    border-bottom: 1px solid #ccc;
    top: 0;
    right: 0;
    line-height: 1.5;
    margin: 0;
    width: 41px;
    padding-right: 10px;
  }
  .message {
    text-align: center;
    padding: 2px 10px;
  }
  .message::before, .message::after {
    display: block;
  }
  .message::before {
    content: "\f10d";
    text-align: left;
  }
  .message::after {
    content: "\f10e";
    text-align: right;
  }
  p.web {
    border-top: 1px solid #ededed;
    font-size: .8rem;
  }
  time {
    display: block;
    text-align: right;
    font-size: .7rem;
    padding: 0 10px 5px 0;
  }
}

.template_livreor_1 .list-messages .message_lo h4 {
  padding-right: 70px;
}

.message_lo:nth-child(2n) {
  margin-right: 0;
}

.livre_dor {
  .form {
    margin-top: 0;
    h2, .h2 {
      margin-bottom: 30px;
    }
    .s4 .s12:last-child {
      display: none;
    }
    .col-xs-9 .s12 {
      width: 370px;
    }
    textarea {
      height: 143px;
    }
    .button {
      margin-right: 90px;
    }
  }
}

/* Fin Livre d'or */

/* Contact */

form {
  margin: 30px auto auto;
}

#connexion-inscription form {
  margin-top: 0;
}

.s12 form {
  max-width: 950px;
}

form label {
  display: block;
  margin-bottom: 8px;
}

form .form-group {
  margin-bottom: 15px;
}

form .form-control {
  padding: 6px 12px;
  box-sizing: border-box;
}

form input, form select {
  height: 31px;
  box-sizing: border-box;
}

form input.button {
  height: auto;
  font-size: 1.2rem;
}

form textarea {
  padding: 5px 5px;
}

.form-group input, .form-group textarea, .formulaires_perso input, .formulaires_perso textarea, .content select {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #a9a9a9;
}

form input:focus, form textarea:focus, form select:focus, #fiche-produit form input:focus,
#fiche-produit form textarea:focus, #fiche-produit form select:focus {
  border: 1px solid;
  box-sizing: border-box;
  outline: 0;
}

.inmap {
  margin-bottom: 30px;
}

input[type=submit]:focus {
  box-shadow: none;
}

/* Fin Contact */

/* Slider accueil */

.accueil_diaporama_template {
  margin-bottom: 0;
  .slide .slide-text {
    .addcommentaires {
      width: 75px;
      height: 55px;
      box-sizing: border-box;
      top: 30px;
      right: 30px;
      margin: 0;
      padding: 0;
      line-height: 55px;
      text-align: center;
    }
    .content-slide-text {
      display: block;
      height: 100%;
    }
    .content-slide-text > p {
      top: auto;
      right: auto;
      margin: 0;
      left: 50%;
      transform: translateX(-50%);
      bottom: 105px;
    }
    .content-slide-text > a.slider-see-more {
      top: auto;
      bottom: 30px;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      padding: 17px 20px;
      margin: 0 auto;
      height: auto;
      line-height: normal;
      text-align: center;
    }
    &.nolink {
      .content-slide-text > p {
        bottom: 30px;
      }
    }
  }
  &.accueil_diaporama_template_5 {
    .slide .slide-text .content-slide-text {
      > a.slider-see-more {
        bottom: 10px;
        padding: 5px 10px;
      }
      > p {
        bottom: 45px;
      }
      > p, > a.slider-see-more {
        left: 5%;
        width: 90%;
        box-sizing: border-box;
        text-align: center;
        transform: translateX(0);
      }
      .addcommentaires {
        top: 10px;
        right: 10px;
      }
    }
  }
}

/* Fin Slider accueil */

#block-flottant-search .btn-search {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

form input[type=radio], form input[type=checkbox] {
  height: auto;
}

/***** FIN CONTENU *****/

/***** FOOTER *****/
footer {
  clear: both;
  img {
    max-width: 100%;
    height: auto !important;
  }
}

#pub {
  clear: both;
}

footer p {
  margin-bottom: .5rem;
}

footer #pub, footer .music {
  text-align: center;
}

footer .music {
}

footer .mention {
  text-align: center;
  padding: 15px 20px 10px;
}

footer .mention a {
  font-size: .8rem;
  text-decoration: none;
  margin: 8px 0;
  display: inline-block;
}

aside.sidebar {
  display: none;
}

/***** FIN FOOTER *****/

/***** IE *****/
/* IE 10 */
.ie10 form input.button, .ie form input.button {
  font-size: 1rem;
}

.ie10 .search-bar-icon, .ie .search-bar-icon {
  vertical-align: top;
}

/* IE 8 */
.ie8 #fiche-produit {
  border-width: 0 77px 77px 0;
}

.ie8 #fiche-produit .remise-produit {
  padding: 25px 16px 3px 15px;
}

.ie8 #fiche-produit .previews .previous, .ie8 #fiche-produit .previews .next {
  font-size: 33px;
}

.ie8 .remise, .ie8 .is_etiquette .remise {
  padding: 7px 5px;
}

.ie8 .message_lo {
  width: 48%;
}

.ie8 .message_lo .note {
  line-height: 1.4;
  padding: 3px 5px;
}

.ie8 .message_lo .message {
  padding: 22px 10px 10px;
}

.message_lo .message::before, .message_lo .message::after {
  font-family: 'fontAwesome', sans-serif;
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
  .side-tabs.etapes,
  div#resume .block-recap-panier,
  div#resume div.next-step,
  div#information #connexion-inscription,
  div#information div.next-step,
  #infoscommande,
  div#finish .block-info-payment,
  div#finish div.next-step,
  div#resume h4,
  div#finish h4,
  .message-commande {
    width: 95%;
  }

  .side-tabs.etapes {
    padding-bottom: 15px;
  }

  .logo-carrier-none {
    width: auto;
  }

  h4 {
    margin: 0;
  }

  div#information .list_address {
    margin: 0 0 20px;
  }
}

/********************* A GARDER POUR LES MODELS ********************/

/*Gérer les images dans les tableaux*/
.content_p {
  position: relative;
}

.content_p img {
  max-width: 100%;
  height: auto !important;
  display: inline-block;
}

.content_p .zoomPupImgWrapper > img, .content_p .zoomWrapperImage > img {
  max-width: none !important;
}

table {
  position: relative;
  table-layout: fixed;
}

.content_p td img {
  max-width: 100% !important;
}

footer.footer_p .links_p td img, footer.footer_p .links_p img {
  width: auto !important;
  max-width: 100% !important;
}

.content_p table {
  max-width: 100% !important;
}

/* Template Panier */
body.cmonsite-panier-2 #nav-principal > ul {
  float: none;
  max-width: 100%;
  + .template-panier.template-panier-2 {
    float: none;
    height: 46px;
    vertical-align: top;
    border-radius: 20px;
    #panier {
      border-radius: 20px;
      #header-panier {
        padding: 14px 10px;
        box-sizing: border-box;
        display: block;
        height: auto;
        .count-live {
          top: auto;
          margin-top: 9px;
          margin-left: 0;
        }
      }
    }
  }
}

.cmonsite-panier-3 #blocContent .template-panier.template-panier-3 {
  border-radius: 30px;
  position: relative;
}

/***** MEDIA QUERIES *****/

#pub {
  width: 1240px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
}

@media #{$xlarge-and-down} {
  .wrapper, #pub {
    width: $large-screen - 20;
  }
  #headercontent {
    padding: 5px 10px;
    height: 90px;
  }
  .page img {
    height: auto;
  }
  .content table {
    max-width: 100% !important;
  }
  #resume table {
    width: 100% !important;
  }

  #fiche-produit .wrap-images .previews .list-previews {
    width: 80%;
    height: 95px;
  }
  #content_full #fiche-produit .wrap-images .previews li {
    height: 95px;
    width: 95px;
  }
  #fiche-produit .ajouter-panier {
    text-align: right;
  }
  #content_full #fiche-produit .wrap-description .addbasket {
    margin: 15px 6px;
  }
  #content_full #fiche-produit .wrap-details#produit-comment {
    padding: 20px 0;
  }
  #fiche-produit #produit-comment > div > .pull-left .pull-left:first-child {
    margin-right: 5px;
  }
  #fiche-produit #produit-comment > div > .pull-right form {
    margin-right: 10px !important;
  }
  .livre_dor .form .button {
    margin-right: 8% !important;
  }
  .form-newsletter input[type=text] {
    width: 85%;
  }
}

@media #{$large-and-down} {
  .wrapper, #pub {
    width: $medium-screen - 20;
  }
}

@media #{$medium-and-down} {
  body .template-panier-2 #header-panier {
    right: 15px;
    border-radius: 50%;
  }
  .wrapper, #pub {
    width: $small-screen - 20;
  }
  /*    .produits select{
          top: 189px;
      }*/

  .template_boutique .addbasket, .produits-accueil .addbasket, #fiche-produit .template_boutique .addbasket {
    height: auto;
  }
  a.bouton, .button, a.button, input[type=submit], a.addmsglo, .addbasket, .diaporama a {
    padding: 7px 16px;
  }
  #fiche-produit .addbasket {
    font-size: .9rem;
    padding: 5px 16px;
  }
  #fiche-produit .wrap-images .previews .previous, #fiche-produit .wrap-images .previews .next {
    font-size: 1.5rem;
    line-height: 3.2;
  }
  #fiche-produit .wrap-description p.note-produit {
    margin-bottom: 25px;
  }
  #fiche-produit .wrap-description .ajouter-panier {
    margin-top: 20px
  }
  .template_fiche_produit {
    &.template_fiche_produit_2 #fiche-produit {
      .triangle-ficheprod {
        right: 0;
      }
      .is_etiquette {
        .triangle-ficheprod {
          top: 40px;
        }
        #sliderImagePreviews {
          margin-top: 40px;
        }
      }
    }
  }
  #content_full #fiche-produit .wrap-description .prix {
    width: 100%;
    margin-bottom: 0;
    margin-top: 5px;
  }
  #content_full #fiche-produit .wrap-description .addbasket {
    margin: 10px 5px;
  }
  #fiche-produit .wrap-description p.description {
    margin-top: 10px;
  }
  #fiche-produit #produit-comment > div > .pull-left {
    float: none !important;
    margin: auto;
    width: 360px;
    overflow: hidden;
  }
  #fiche-produit #produit-comment > div > .pull-right {
    float: none !important;
    margin: auto;
    width: 192px;
  }

  form > .s8:last-child > .s12:last-child > .s8 {
    width: 100%;
  }
  form > .s8:last-child > .s12:last-child > .s4 {
    float: right;
    margin-top: -38px;
  }
  .form-newsletter input[type=text] {
    width: 82%;
  }
  #resume .button, #information .button {
    padding: 7px 14px;
  }

  .navbar-fixed-top #headercontent {
    padding: 0;
  }
  #headercontent {
    height: 80px;
  }
  nav a {
    padding: 10px 22px;
  }
  .content h1, .content .h1 {
    font-size: 1.7rem;
  }
  .content h2, .content .h2 {
    font-size: 1.5rem;
  }
  .content h3, .content .h3 {
    font-size: 1.4rem;
  }
  .content h4, .content .h4 {
    font-size: 1.3rem;
  }
  .content h5, .content .h5 {
    font-size: 1.2rem;
  }
  .content h6, .content .h6 {
    font-size: 1.1rem;
  }
  blockquote {
    padding: 1px 10px;
    margin: 10px 0;
  }

  .productImageWrap {
    margin-top: 26px;
  }
  .produit_etiquette h3,
  .produit_etiquette .h3 {
    padding: 3px 2px;
  }
  .nomprod, .desc, .produits .prix {
    font-size: .86rem;
  }
  .produits .zoom {
    font-size: .9rem;
    padding: 8px 18px;
  }
  .produits .zoom:hover {
    padding: 7px 17px;
  }
  .zoom:before {
    font-size: .9rem;
  }
  /*    .produits select{
          top: 180px;
      }*/
  a.bouton, .button, a.button, input[type=submit], a.addmsglo, .addbasket, .diaporama a {
    padding: 7px 12px;
  }
  a.bouton:hover, .button:hover, a.button:hover, input[type=submit]:hover, a.addmsglo:hover, .addbasket:hover, .diaporama a:hover {
    padding: 6px 11px;
  }
  #content_full #fiche-produit .wrap-details h4 {
    font-size: 22px;
  }
  #content_full #fiche-produit .wrap-details#savoirplus {
    padding: 20px 0;
  }
  #fiche-produit .form-group {
    padding: 0 8px;
  }

  .message_lo {
    width: 100%;
  }
  .message_lo h4 {
    font-size: .8rem;
    width: 87%
  }
  .livre_dor .form .col-xs-9 .s12 {
    width: 100%;
  }
  .row .col.s3 {
    width: 25%;
  }

  .formulaires_perso div.center, .form-newsletter .center {
    font-size: 1.2rem;
  }
}

@media #{$medium-and-up}{
  .menu_burger {
    display: none;
  }
}

@media #{$small-and-down} {
  /* Tableaux responsive */
  @import '../../design/helper/responsive/mobile/mobile';

  /* Template Responsive Panier */
  .cmonsite-panier-2 {
    header nav#nav-principal {
      max-width: 70%;
      max-width: calc(100% - 80px);
      margin: 5px 0 10px 10px;
    }
    .template-panier.template-panier-2 {
      #panier #header-panier {
        top: auto;
        bottom: 15px;
        right: -60px;
      }
      #paniercontent {
        right: -60px !important;
        top: -5px;
      }
    }
  }

  #headercontent {
    height: auto;
  }
  .slogan {
    left: 0 !important;
    top: 0 !important;
    right: auto !important;
    bottom: auto !important;
    text-align: center !important;
    font-size: 18px !important;
    width: 100% !important;
    position: relative !important;
    padding-bottom: 15px !important;
    float: none !important;
    height: auto !important;
  }
  .logo {
    padding-bottom: 15px;
    position: static !important;
    display: block !important;
    margin: 0 auto 15px !important;
    padding-top: 15px;
    height: auto !important;
    text-align: center;
    width: 100% !important;
    float: none !important;
    img {
      max-height: 70px !important;
      width: auto !important;
      max-width: 100% !important;
      height: auto !important;
      position: static !important;

    }
  }

  .wrapper, #pub {
    width: 100%;
    padding: 30px 10px 50px;
  }
  nav#nav-principal {
    margin: 5px auto;
  }
  aside#minisite-sidebar {
    display: none;
  }
  .produits-accueil .produits .zoom {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  #content_full #fiche-produit .wrap-description > .row {
    display: block;
    margin-bottom: 15px;
  }
  #content_full #fiche-produit .wrap-description .col.s8, #content_full #fiche-produit .wrap-description .col.s4 {
    width: 100%;
    display: block;
  }

  .search-bar .search-bar-icon {
    width: 8%;
  }
  .search-bar button {
    padding: 6px 27%;
  }
  .form-newsletter {
    input[type=text], input[type=submit] {
      display: block;
      margin: 3px auto;
    }
    input[type=text] {
      width: 90%;
    }
    input[type=submit] {
      width: 86px;
    }
  }
  #resume .button, #information .button {
    padding: 7px 8px;
    font-size: 15px;
  }

  .produits .zoom {
    display: block;
    margin: auto;
    text-align: center;
  }
  #fiche-produit .triangle-ficheprod {
    border-width: 0 68px 68px 0;
  }
  #fiche-produit .triangle-ficheprod .remise-produit {
    font-size: 1rem;
    padding: 8px 9px 5px 15px;
  }
  #fiche-produit .wrap-description .prix .prixprod {
    font-size: 1.1rem;
  }
  #fiche-produit .declinaison, #fiche-produit .ajouter-panier {
    width: 100%;
    float: left;
  }
  #fiche-produit .pull-left {
    float: none !important;
  }
  #fiche-produit #produit-comment > div > .pull-left {
    text-align: center;
  }
  #fiche-produit #produit-comment > div > .pull-left .pull-left:first-child p {
    margin-bottom: 0;
    margin-top: 0;
  }
  #fiche-produit #produit-comment > div > .pull-left .pull-left:last-child p {
    margin-top: 10px;
  }
  #fiche-produit #produit-comment > div > .pull-right {
    width: 140px;
    p {
      margin-top: 22px;
    }
  }
  #fiche-produit #produit-comment .message-wrap .pull-left:first-child {
    width: 100%;
  }
  #produit-comment .message-wrap .pull-left:first-child div {
    float: left;
    margin-right: 10px;
  }
  #produit-comment .message-wrap .pull-left:first-child > span:nth-child(2) {
    float: left;
  }
  #fiche-produit #produit-comment .message-wrap .pull-left:nth-child(2) p {
    text-align: center;
    margin-top: 0;
    margin-bottom: 5px;
  }
  #fiche-produit #produit-comment .message-wrap .pull-left:first-child > span:nth-child(5) {
    margin-top: 0;
  }
  #fiche-produit .button {
    font-size: .8rem;
    padding: 3px 11px;
  }
  #fiche-produit .addbasket {
    padding: 5px 12px;
  }
  #fiche-produit .addbasket i {
    display: none;
  }
  #fiche-produit form .form-group {
    width: 100%;
  }
  .container-comment > div {
    padding: 10px;
  }
  .content #fiche-produit #captcha, #fiche-produit #captcha img {
    width: 100%;
  }
  #fiche-produit #captcha input {
    padding: 13px 8px;
  }
  .content #fiche-produit #captcha div:first-child {
    margin-right: 3%;
  }
  #captcha div, .content #fiche-produit #captcha div:last-child {
    margin: auto;
    width: 48%;
  }

  .form-newsletter input[type=text] {
    width: 95%;
  }
  #resume .button, #information .button {
    font-size: 14px;
  }
  .accueil_diaporama_template.accueil_diaporama_template_5 .slide .slide-text .content-slide-text {
    > a.slider-see-more {
      top: auto !important;
      max-width: 90% !important;
    }
  }

  /* Nav mobile */
  header.header_p {
    background-position: 0 center;
  }
  header nav#nav-principal {
    text-align: left;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 0;
    position: relative;
    width: 90%;
    border-radius: 20px;
  }
  nav#nav-principal a {
    padding: 7px 24px;
  }
  .menu_burger {
    cursor: pointer;
    background-color: #fff;
    border-radius: 20px;
    display: inline-block;
    float: none;
    width: 100%;
    box-sizing: border-box;
  }
  .menu_burger::before {
    font-family: FontAwesome, sans-serif;
    content: "\f0c9";
    margin-right: 5px;
  }
  header nav#nav-principal > ul {
    display: none;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 2px rgba(11, 25, 28, 0.3);
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=174, Strength=10);
  }
  nav#nav-principal.nav_fermee > ul {
    display: none;
  }
  nav#nav-principal.nav_ouverte > ul {
    display: block;
    position: absolute;
    width: 100%;
  }
  header nav#nav-principal > ul > li {
    float: none;
    margin-right: 0;
    display: block;
    border-radius: 0;
    border: 0;
  }
  nav#nav-principal > ul > li:hover {
    animation: none;
  }
  header nav#nav-principal > ul > li:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  header nav#nav-principal > ul > li:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  nav#nav-principal .navigation_button_p {
    margin-bottom: 0;
    border-bottom: 1px solid #ccc;
  }
  .navigation_button_p::before, .navigation_button_p::after {
    border: none;
  }

  nav#nav-principal li li:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  nav#nav-principal li li:first-child {
    overflow: hidden;
  }
  .dropDown {
    position: absolute;
    right: 2%;
    top: 4px;
    font-weight: bold;
    display: block;
    height: 20px;
    width: 7%;
    cursor: pointer;
    text-align: center;
    font-size: 1.2rem;
  }
  /*    .sousmenu_fermer ul{
          display: none;
      }
      .sousmenu_ouvert > ul{
          display: block;
      }*/
  nav#nav-principal li ul {
    display: block;
    position: relative;
    text-align: left;
    border: none;
    border-radius: 0;
  }

  #fiche-produit #captcha {
    width: 100%;
  }
  .g-recaptcha iframe {
    width: 104% !important;
  }
  .rc-anchor-standard {
    width: 100% !important;
  }
  .rc-anchor-content {
    width: 64% !important;
  }
  .rc-anchor-content .rc-anchor-item:last-child {
    width: 67% !important;
  }
  .rc-anchor-privacy {
    width: 93%;
  }
  a.addmsglo {
    width: 100%;
  }

  .productImageWrap {
    height: auto;
  }
  .is_etiquette .remise, .remise {
    font-size: .75rem;
    padding: 8px 8px 13px 5px;
  }
  .content .produit_etiquette h3,
  .content .produit_etiquette .h3 {
    line-height: 1.5;
  }
  .produits .mask {
    opacity: 1;
    top: initial;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .produits-accueil .produits .zoom {
    top: initial;
  }
  .produits .addbasket {
    border: 1px solid transparent;
  }
  .template_boutique .addbasket, .produits-accueil .addbasket, .hors-stock, #fiche-produit .template_boutique .addbasket, .produits-accueil .produits .addbasket {
    display: block;
    text-align: center;
    width: 100%;
  }
  .produits .hors-stock {
    margin-top: 24%;
    width: 100%;
  }
  .hors-stock {
    height: auto;
  }
  .template_boutique .addbasket:before, .produits-accueil .addbasket:before {
    margin-right: 8px;
  }
  .produits .zoom {
    font-size: .8rem;
    padding: 6px 0;
  }
  .produits .zoom:hover {
    padding: 5px 0;
  }

  .zoom:before {
    margin-right: 5px;
  }
  .nomprod, .desc, .produits .prix {
    font-size: 0.75rem;
  }

  #paniercommandecontent #totalbloc {
    width: 235px;
  }
  #paniercmd #infoscommande fieldset {
    padding-left: 5px;
  }
  form input.button {
    font-size: 1em;
  }
  .form-newsletter input[type=text] {
    width: 100%;
  }

  /* Slider boutons */
  .accueil_diaporama_template .slide .slide-text .content-slide-text > a.slider-see-more {
    top: 5px !important;
    bottom: auto !important;
    right: auto !important;
    left: 5px !important;
    transform: translateX(0);
    max-width: calc(100% - 75px);
  }
  .accueil_diaporama_template .slide .slide-text .addcommentaires {
    top: 5px !important;
    right: 5px !important;
  }
  .accueil_diaporama_template .slide .slide-text .content-slide-text > p {
    top: auto !important;
    right: auto !important;
    left: 5px !important;
    transform: translateX(0);
    bottom: 5px !important;
  }

  nav#nav-principal ul ul ul {
    position: relative;
    left: 0;
    border-radius: 0;
  }
}

@media screen and (max-width: 400px) {

  .content table {
    h3, .h3, p {
      word-break: break-all;
    }
  }
  #fiche-produit #produit-comment > div > .pull-right form, #fiche-produit #produit-comment > div > .pull-right p {
    float: none !important;
  }
  #fiche-produit #produit-comment > div > .pull-right p {
    margin-top: 10px;
    margin-bottom: 0
  }
  #fiche-produit #produit-comment > div > .pull-right {
    text-align: center;
    width: 160px;
  }
  .template_boutique .addbasket:before, .produits-accueil .addbasket:before, .zoom:before {
    content: none;
  }
  .template_boutique .addbasket, .produits-accueil .addbasket, .hors-stock,
  #fiche-produit .template_boutique .addbasket, .produits-accueil .produits .addbasket {
    margin-top: 40%;
  }
}

@media screen and (max-width: 360px) {
  body {
    font-size: 0.85rem;
  }
  .content h1, .content .h1 {
    font-size: 1.4rem;
  }
  .content h2, .content .h2 {
    font-size: 1.2rem;
  }
  h2::before, .h2::before {
    border-width: 27px 10px 0 0;
  }
  h2::after, .h2::after {
    border-width: 0 0 27px 10px;
  }
  .content h3, .content .h3 {
    font-size: 1.1rem;
  }
  .content h4, .content .h4 {
    font-size: 1rem;
  }
  .content h5, .content .h5 {
    font-size: .9rem;
  }
  .content h6, .content .h6 {
    font-size: .85rem;
  }
  .content {
    padding: 20px 8px 30px;
  }

  .produits .mask {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .produits .zoom {
    top: 42%;
  }
  #fiche-produit .button:hover {
    padding: 3px 10px;
  }
  #fiche-produit .produits .zoom {
    top: 44%;
  }
  .template_boutique .addbasket, .produits-accueil .addbasket, .hors-stock, #fiche-produit .template_boutique .addbasket {
    margin-top: 50%;
  }
  .produits .hors-stock {
    margin-top: 29%;
  }
  #fiche-produit .template_boutique .addbasket:hover {
    padding: 4px 0;
  }
  #content_full #fiche-produit .wrap-images {
    width: 95%;
  }
  #content_full #fiche-produit .wrap-description .addbasket {
    font-size: 1.4em;
    padding: 5px 25px;
  }
  #fiche-produit .wrap-description .details-ficheprod a {
    line-height: 1.4;
  }
  #fiche-produit #addcomment {
    padding: 0 6px;
  }
  #fiche-produit #produit-comment .message-wrap .pull-left:first-child div span {
    margin-left: 0;
  }
  .hors-stock {
    font-size: .9em;
  }
  .content #fiche-produit .wrap-images, .content #fiche-produit .wrap-description {
    width: 100%;
  }
  .container-comment > div {
    padding: 20px 5px !important;
  }
  .content #fiche-produit #captcha {
    width: 100%;
  }
  #fiche-produit #captcha img {
    margin-bottom: 5px;
  }
  .content #fiche-produit #captcha > div, #fiche-produit #captcha div img {
    float: none;
    margin: auto;
  }
  .content #fiche-produit #captcha div:first-child {
    margin: auto;
    width: 58%;
  }
  .zoomPad:hover {
    cursor: default;
  }
  .zoomPad:hover img {
    opacity: 1 !important;
  }
  .zoomPad > img {
    max-width: 100%;
    margin: auto;
    display: block;
  }
  .zoomPup, .zoomWindow {
    display: none !important;
  }

  .search-bar .search-bar-icon {
    width: 11%;
  }
  .search-bar input, .search-bar button {
    padding: 5px 6px;
  }

  a.addmsglo {
    width: 100%;
  }
  .message_lo h4 {
    font-size: .75rem;
    width: 93.5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .message_lo .note {
    line-height: 1.65;
    border-top-right-radius: 20px;
  }

  .formulaires_perso div.center, .form-newsletter .center {
    font-size: 1rem;
  }
  input.hasDatepicker {
    margin-bottom: 3px;
  }
  input[type=file] {
    padding: 0 !important;
  }

  .content .row .col {
    padding: 0 0.2rem;
  }
  body #panier {
    width: 236px;
  }
  #panier #header-panier {
    padding: 4px 5px 6px;
  }
  #panier .ajout_panier {
    margin: 6px;
  }
  #paniercommandecontent #totalbloc {
    width: 220px;
    float: none;
    margin: auto;
  }
  form input.button {
    font-size: .9em;
  }
  form textarea {
    width: 100% !important;
  }
  a.bouton, .button, a.button, input[type="submit"], a.addmsglo, a.addbasket, .diaporama a, a.bouton:visited, .button:visited, a.button:visited, a.addmsglo:visited, a.addbasket:visited, .diaporama a:visited {
    padding: 5px 14px;
  }
  a.bouton:hover, .button:hover, a.button:hover, input[type="submit"]:hover, a.addmsglo:hover, .addbasket:hover, .diaporama a:hover {
    padding: 4px 13px;
  }
  #paniercmd #code_reponse {
    width: 274px;
  }

  .ui-datepicker {
    width: 90% !important;
    font-size: .8em;
  }
  .ui-datepicker td {
    display: table-cell;
    width: auto;
  }
}

@media screen and (max-width: 320px) {
  .content {
    padding: 15px 10px 25px;
  }
  .content h1, .content .h1 {
    font-size: 1.2rem;
  }
  .content h2, .content .h2 {
    font-size: 1.1rem;
  }
  h2::before, .h2::before {
    border-width: 25px 10px 0 0;
  }
  h2::after, .h2::after {
    border-width: 0 0 25px 10px;
  }
  .content h3, .content .h3 {
    font-size: 1rem;
  }
  .content h4, .content .h4 {
    font-size: .9rem;
  }
  .content h5, .content .h5 {
    font-size: .85rem;
  }
  .content h6, .content .h6 {
    font-size: .825rem;
  }
  blockquote {
    padding: 0 10px;
  }
  .productImageWrap {
    height: auto;
  }
  .produits .zoom {
    padding: 6px 0;
  }
  .produits .zoom:hover {
    padding: 5px 0;
  }

  .message_lo h4 {
    font-size: .7rem;
  }
  .message_lo .note {
    line-height: 1.55;
  }
}

.template_boutique.template_boutique_1 .produits {
  .addbasket {
    margin-top: 15px;
  }
  .hors-stock {
    height: 44px;
  }
}

.wrapper #fiche-produit .top_fiche .addbasket {
  margin: 10px 20px;
}

.template_boutique.template_boutique_2 .produits .addbasket {
  margin-top: 5px;
}

//Centrer en hauteur les titres des produits
.template_boutique.template_boutique_1, .template_boutique.template_boutique_2 {
  .produits .nomprod {
    box-sizing: border-box;
    display: table;
    width: 100%;
    a {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

.template_boutique.template_boutique_3 {
  .produits {
    .addbasket {
      font-size: 1.1em !important;
      padding: 10px !important;
      height: auto !important;
    }
  }
}

.template_album {
  .galerie .commentP p {
    text-align: center;
  }
  &.template_album_2 {
    .addcommentaires {
      bottom: auto;
      top: 15px;
    }
  }
  &.template_album_2,
  &.template_album_3 {
    .commentP p {
      vertical-align: bottom;
      margin: 0;
      color: #fff;
    }
  }
}

body .template_album_3 .list-photos .galerie.sans-texte .bloc-cache-overflow {
  display: none;
}

@media #{$small-and-down} {
  .template_boutique {
    .produits {
      .mask {
        opacity: 1;
        background: transparent;
        .zoom {
          top: 95%;
          margin-top: -35px;
        }
      }
    }
  }
  .produits-accueil .produits .mask .lien-mask {
    opacity: .1;
  }
}

@media #{$medium-and-down}{
  .template_boutique.template_boutique_3 {
    .produits {
      .addbasket {
        font-size: 0.8rem;
        padding: 7px 12px;
      }
    }
  }
}

#fiche-produit {
  .wrap-description {
    .triangle-ficheprod,
    .triangle-ficheprod-second {
      .remise-produit {
        font-size: 1.2rem;
        padding: 5px;
        height: auto;
      }
    }
  }
}

.list-produits {
  .produits {
    .addbasket {
      margin-bottom: 10px !important;
    }
    .attr {
      margin: 0 auto 10px !important;
    }
    .hors-stock {
      width: 100%;
      float: none;
      text-align: center;
      margin-bottom: 10px !important;
    }
  }
}

#fiche-produit .wrap-description .ajouter-panier {
  background: rgba(255, 255, 255, .1);
}

.template_boutique.template_boutique_3 .produits {
  padding: 10px;
}

#fiche-produit .template_boutique.template_boutique_2 .produits .hors-stock {
  height: 44px;
}

/* Produits page accueil*/
.accueil_boutique_template_1 .produits {
  .addbasket {
    width: 90%;
  }
}

.accueil_boutique_template_2 .produits .attr {
  text-align: center;
  select {
    display: inline-block;
    position: relative;
    right: 0;
  }
}

.produits-accueil.template_boutique_accueil .produits.is_etiquette .remise {
  top: 40px;
}

.produits-accueil.template_boutique_accueil .produits .mask, .template_boutique.template_boutique_3 .produits .productImageWrap {
  border-radius: 20px;
  overflow: hidden;
}

.cmonsite-ficheproduit.cmonsite-template #bloc-fil-title {
  h2, .h2, h1, .h1 {
    display: none;
  }
}

.modif-design {
  #main-conteneur header.header-fixed {
    max-width: calc(100% - 240px);
    left: 240px;
  }
}